import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { signupUser } from '../service/handlesignUp'; // Import the signup function
import '../style/SignUp.css';

const RegisterPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const navigate = useNavigate();

  // Validation schema using Yup
  const validationSchema = Yup.object({
    full_name: Yup.string().required('Full Name is required'),
    email_address: Yup.string().email('Invalid email address').required('Email is required'),
    date_of_birth: Yup.date()
      .required('Date of Birth is required')
      .test('is-18', 'You must be at least 18 years old', (value) => {
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        return age >= 18;
      }),
    address: Yup.string().required('Address is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    gender: Yup.string().required('Gender is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/(?=.*[0-9])/, 'Password must contain a number')
      .matches(/(?=.*[@$!%*#?&])/, 'Password must contain a special character')
      .required('Password is required'),
    retypePassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Please retype your password'),
    termsAccepted: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      // Prepare the form data for the backend
      const userData = {
        full_name: values.full_name,
        email_address: values.email_address,
        date_of_birth: values.date_of_birth,
        address: values.address,
        phone_number: values.phone_number,
        gender: values.gender,
        password: values.password,
      };

      const response = await signupUser(userData); // Send form data to backend

      // Show success message
      alert('User registered successfully!');
      navigate('/signin'); // Redirect to signin page
    } catch (err) {
      console.error('Sign-up error:', err);
      setErrors({ apiError: err.message || 'An error occurred during signup' });
      setSubmitting(false);
    }
  };

  return (
    <section className="register-page">
      <div className="register-container">
        <div className="register-form">
          <h1 className="register-title">Register</h1>
          <Formik
            initialValues={{
              full_name: '',
              email_address: '',
              date_of_birth: '',
              address: '',
              phone_number: '',
              gender: '',
              password: '',
              retypePassword: '',
              termsAccepted: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                <div className="register-form-group">
                  <label htmlFor="full_name">Full Name</label>
                  <Field type="text" id="full_name" name="full_name" />
                  <ErrorMessage name="full_name" component="div" className="error" />
                </div>

                <div className="register-form-group">
                  <label htmlFor="email_address">Email Address</label>
                  <Field type="email" id="email_address" name="email_address" />
                  <ErrorMessage name="email_address" component="div" className="error" />
                </div>

                <div className="register-form-group">
                  <label htmlFor="date_of_birth">Date of Birth</label>
                  <Field type="date" id="date_of_birth" name="date_of_birth" />
                  <ErrorMessage name="date_of_birth" component="div" className="error" />
                </div>

                <div className="register-form-group">
                  <label htmlFor="address">Address</label>
                  <Field type="text" id="address" name="address" />
                  <ErrorMessage name="address" component="div" className="error" />
                </div>

                <div className="register-form-group">
                  <label htmlFor="phone_number">Phone Number</label>
                  <Field type="tel" id="phone_number" name="phone_number" />
                  <ErrorMessage name="phone_number" component="div" className="error" />
                </div>

                <div className="register-form-group">
                  <label htmlFor="gender">Gender</label>
                  <div className="gender-input-container">
                    <Field as="select" id="gender" name="gender">
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Field>
                  </div>
                  <ErrorMessage name="gender" component="div" className="error" />
                </div>

                <div className="register-form-group">
                  <label htmlFor="password">Password</label>
                  <div className="password-input-container">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <ErrorMessage name="password" component="div" className="error" />
                </div>

                <div className="register-form-group">
                  <label htmlFor="retypePassword">Retype Password</label>
                  <div className="password-input-container">
                    <Field
                      type={showRetypePassword ? "text" : "password"}
                      id="retypePassword"
                      name="retypePassword"
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={() => setShowRetypePassword(!showRetypePassword)}
                    >
                      {showRetypePassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <ErrorMessage name="retypePassword" component="div" className="error" />
                </div>

                <div className="register-form-group terms">
                  <Field type="checkbox" id="termsAccepted" name="termsAccepted" />
                  <label htmlFor="termsAccepted">
                    I have read and accept the <a href='/terms-and-conditions'> terms and conditions</a>
                    {/* <Link to="/terms-and-conditions" target="_blank">
                      terms and conditions
                    </Link> */}
                  </label>
                  <ErrorMessage name="termsAccepted" component="div" className="error" />
                </div>

                {errors.apiError && <div className="error">{errors.apiError}</div>}

                <button type="submit" className="cta-button" disabled={isSubmitting}>
                  {isSubmitting ? <ClipLoader size={20} color={'#fff'} /> : 'Register'}
                </button>
              </Form>
            )}
          </Formik>
          <div className="register-footer">
            <p>
              Already have an account? <Link to="/signin" className="forgot-password-link">Sign in here</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterPage;
