import React, { useEffect, useState } from 'react';
import '../style/About.css'; // Import your custom styles
import { fetchAboutData } from '../service/aboutimg'; // Import the service

const About = () => {
    const [aboutData, setAboutData] = useState(null);

    useEffect(() => {
        const getAboutData = async () => {
            const data = await fetchAboutData();
            setAboutData(data);
        };

        getAboutData(); // Fetch data on component mount
    }, []);

    return (
        <section className="about">
            <div className="container">
                <h1 className="about-title">About NNBBA</h1>
                <p className="about-subtitle">
                    Welcome to the Nigeria Natural Bodybuilding Association (NNBBA), the premier organization dedicated to promoting the sport of natural bodybuilding in Nigeria.
                </p>

                <div className="about-content">
                    <div className="about-image">
                        {aboutData?.imageUrl ? (
                            <img
                                src={aboutData.imageUrl}
                                alt={aboutData.altText || 'About image'}
                            />
                        ) : (
                            <p>No image available</p>
                        )}
                        <p className="image-label">{aboutData?.author || 'Mr. Arinze T Oraekwotu'}</p>
                    </div>

                    <div className="about-text">
                        <p>
                            {aboutData?.description || 'Our mission is to foster a community of like-minded individuals who share a passion for health, fitness, and self-improvement through natural bodybuilding.'}
                        </p>
                        <p>
                            We aim to provide a platform for athletes to develop their physiques without the use of performance-enhancing substances and to promote a lifestyle that values discipline, hard work, and dedication.
                        </p>
                        <p>
                            Through our events, training programs, and resources, we strive to empower individuals to reach their full potential and become the best versions of themselves.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
