// src/service/partnersApi.js
import { API_BASE_CMS_URL } from './api.js';

export const fetchPartnersData = async () => {
    try {
        const response = await fetch(`${API_BASE_CMS_URL}/api/partners?populate=*`); // Adjust the endpoint if needed
        const data = await response.json();

        if (data && data.data) {
            // Flatten the nested images to get all relevant URLs
            const partners = data.data.flatMap(item => item.attributes.Partners.data.map(partner => {
                const formats = partner.attributes.formats;
                const url = formats?.small?.url || formats?.thumbnail?.url || partner.attributes.url;
                return {
                    id: partner.id,
                    url: url,
                    name: partner.attributes.name || `Partner ${partner.id}`
                };
            }));
            return partners;
        }
        return [];
    } catch (error) {
        console.error("Error fetching partners data:", error);
        return [];
    }
};
