import React, { useEffect, useState } from 'react';
import '../style/gallery.css';
import { fetchGalleryData } from '../service/galleryApiService.js';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

const Gallery = () => {
    const [galleryData, setGalleryData] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(true); // State to track overall loading
    const [modalLoading, setModalLoading] = useState(false); // State for modal image loading

    useEffect(() => {
        const getGalleryData = async () => {
            const data = await fetchGalleryData();

            if (data && data.data) {
                // Flatten the nested images
                const images = data.data.flatMap(item => item.attributes.Images.data);
                setGalleryData(images);
            }

            setLoading(false); // Stop loading when data is fetched
        };

        getGalleryData();
    }, []);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalLoading(true); // Start loading when opening modal
    };

    const closeModal = () => {
        setSelectedImage(null);
        setModalLoading(false); // Reset loading when closing modal
    };

    const handleImageLoad = () => {
        setModalLoading(false); // Stop loading when image is loaded
    };

    return (
        <section className="gallery">
            <div className="container">
                <div className="gallery-header">
                    <h1 className="gallery-title">Gallery</h1>
                </div>

                {loading ? (
                    <div className="loading-spinner">Loading...</div> // Display loading spinner when fetching data
                ) : (
                    <div className="gallery-content">
                        {galleryData.length > 0 ? (
                            galleryData.map((item, index) => (
                                <div key={index} className="gallery-card" onClick={() => openModal(item)}>
                                    <img
                                        src={item.attributes.formats.thumbnail.url}
                                        alt={item.attributes.name || 'Gallery Image'}
                                        className="gallery-image"
                                    />
                                </div>
                            ))
                        ) : (
                            <p>No images available.</p>
                        )}
                    </div>
                )}
            </div>

            {selectedImage && (
                <Modal
                    isOpen={!!selectedImage}
                    onRequestClose={closeModal}
                    className="gallery-modal"
                    overlayClassName="gallery-overlay"
                >
                    {modalLoading && <div className="loading-spinner">Loading...</div>} {/* Display spinner in modal */}
                    <img
                        src={selectedImage.attributes.url}
                        alt={selectedImage.attributes.name || 'Gallery Image'}
                        className="modal-image"
                        onLoad={handleImageLoad} // Trigger on image load
                        style={modalLoading ? { display: 'none' } : {}} // Hide image while loading
                    />
                    <button onClick={closeModal} className="modal-close-button">Close</button>
                </Modal>
            )}
        </section>
    );
};

export default Gallery;
