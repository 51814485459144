import { API_BASE_CMS_URL } from './api.js';

export const fetchVideoUrl = async () => {
    try {
        const response = await fetch(`${API_BASE_CMS_URL}/api/hero-page-video?populate=*`);
        const data = await response.json();

        // Extract the video URL from the API response using the correct path
        const videoUrl = data.data?.attributes?.HeroPageVideo?.data?.attributes?.url;

        // console.log("Video URL:", videoUrl); // Log the extracted video URL
        // console.log("Full Response Data:", data); // Log the entire response for debugging

        return videoUrl;
    } catch (error) {
        console.error("Error fetching video URL:", error);
        return null;
    }
};
