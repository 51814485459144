import React, { useState, useEffect } from "react";
import { Layout, Menu, Card, Spin, message, Button } from "antd";
import { UserOutlined, CreditCardOutlined, HistoryOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { fetchUserData } from "../service/fetchUser"; // Import fetchUserData service
import { PaystackButton } from "react-paystack"; // Import PaystackButton

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
    const [selectedKey, setSelectedKey] = useState("overview");
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { token } = useParams();
    const navigate = useNavigate();

    // Fetch user data when the component loads
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchUserData(token);
                setUserData(data);
            } catch (error) {
                message.error("Error fetching user data");
                navigate("/signin");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token, navigate]);

    const handleMenuClick = (e) => {
        setSelectedKey(e.key);
    };

    const handleSignOut = () => {
        localStorage.removeItem("token");
        navigate("/signin");
    };

    const renderContent = () => {
        switch (selectedKey) {
            case "profile":
                return <ProfileUpdate />;
            case "payments":
                return <Payments userData={userData} />;
            case "overview":
            default:
                return <Overview userData={userData} loading={loading} />;
        }
    };

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider width={70} style={{ background: "#001529" }}>
                <Menu
                    theme="dark"
                    mode="vertical"
                    selectedKeys={[selectedKey]}
                    onClick={handleMenuClick}
                >
                    <Menu.Item key="overview" icon={<HistoryOutlined />} />
                    <Menu.Item key="profile" icon={<UserOutlined />} />
                    <Menu.Item key="payments" icon={<CreditCardOutlined />} />
                </Menu>
            </Sider>
            <Layout className="site-layout" style={{ marginLeft: 80 }}>
                <Header
                    className="site-layout-background"
                    style={{
                        backgroundColor: "#fff",
                        padding: 0,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingRight: "16px",
                        position: "fixed",
                        top: 0,
                        left: 80,
                        right: 0,
                        zIndex: 9,
                    }}
                >
                    <h1 style={{ margin: 10 }}>Dashboard</h1>
                    <Button type="primary" danger onClick={handleSignOut}>
                        Sign Out
                    </Button>
                </Header>

                <Content
                    style={{
                        marginTop: "64px",
                        padding: "16px",
                        overflow: "initial",
                    }}
                >
                    <div
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            minHeight: 360,
                            maxWidth: "1200px",
                            margin: "0 auto",
                        }}
                    >
                        {renderContent()}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

const Payments = ({ userData }) => {
    const publicKey = "your_paystack_public_key"; // Add your public key here
    const amount = 10000; // Example amount in Kobo (Naira value x 100)
    const [email, setEmail] = useState(userData ? userData.email_address : ""); // Use the user's email

    const onSuccess = (reference) => {
        console.log("Payment successful", reference);
        message.success("Payment Successful!");
        // Handle success (e.g., update subscription status)
    };

    const onClose = () => {
        message.info("Payment was cancelled.");
    };

    const paystackConfig = {
        email: email,
        amount: amount,
        publicKey: publicKey,
        onSuccess,
        onClose,
    };

    if (!userData) {
        return <Spin size="large" />;
    }

    return (
        <Card title="Payments">
            <div style={{ marginBottom: "16px" }}>
                <h2>Subscription Status: {userData.subscription_status ? "Active" : "Inactive"}</h2>
                <h3>Last Payment Date: {new Date(userData.last_payment_date).toLocaleString()}</h3>
            </div>

            <h2>Make a Payment</h2>
            <PaystackButton {...paystackConfig} className="ant-btn ant-btn-primary">
                Subscribe with Paystack
            </PaystackButton>

            <h2 style={{ marginTop: "20px" }}>Payment History</h2>
            <ul>
                {userData.payment_history.map((payment, index) => (
                    <li key={index}>
                        <p><strong>Payment Date:</strong> {new Date(payment.payment_date).toLocaleString()}</p>
                        <p><strong>Amount:</strong> ${payment.amount}</p>
                        <p><strong>Status:</strong> {payment.status}</p>
                    </li>
                ))}
            </ul>
        </Card>
    );
};

const Overview = ({ userData, loading }) => {
    if (loading) {
        return <Spin size="large" />;
    }

    if (!userData) {
        return <p>No user data available.</p>;
    }

    return (
        <Card title="Overview">
            <div style={{ marginBottom: "16px" }}>
                <p>
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Full Name: </span>
                    <span style={{ fontSize: "16px" }}>{userData.full_name}</span>
                </p>
                <p>
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Email: </span>
                    <span style={{ fontSize: "16px" }}>{userData.email_address}</span>
                </p>
                <p>
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Date of Birth: </span>
                    <span style={{ fontSize: "16px" }}>{userData.date_of_birth}</span>
                </p>
                <p>
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Address: </span>
                    <span style={{ fontSize: "16px" }}>{userData.address}</span>
                </p>
                <p>
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Phone Number: </span>
                    <span style={{ fontSize: "16px" }}>{userData.phone_number}</span>
                </p>
            </div>
        </Card>
    );
};

const ProfileUpdate = () => {
    return <div>Profile Update Section</div>;
};

export default Dashboard;
