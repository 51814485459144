import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About';
import Event from '../components/Events';
import Nutrition from '../components/Nutrition';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import PopupPage from '../components/Popup'; // Import the PopupPage
import Partners from '../components/Partners';

const LandingPage = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Check if the popup was already shown in this session
        const popupShown = sessionStorage.getItem('popupShown');
        if (!popupShown) {
            setShowPopup(true);
            sessionStorage.setItem('popupShown', 'true'); // Set flag to avoid repeated popups
        }
    }, []);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            <Header />
            <Hero />
            <About />
            <Event />
            <Nutrition />
            <Partners />
            {/* <Contact /> */}
            <Footer />

            {/* Conditionally render the popup */}
            {showPopup && <PopupPage onClose={handleClosePopup} />}
        </>
    );
};

export default LandingPage;
