import axios from 'axios';
import { API_BASE_URL } from './api.js';

export const fetchUserData = async (token) => {
    try {
        // Fetch user data using the token in the Authorization header
        const response = await axios.get(`${API_BASE_URL}/active_users`, {
            headers: {
                Authorization: `Bearer ${token}`, // Pass the token as a Bearer token
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error; // Rethrow to handle it in the Dashboard component
    }
};
