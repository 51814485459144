import React, { useState, useEffect } from 'react';
import { getPopupData } from '../service/popupService.js';
import '../style/PopUp.css';

const PopupPage = ({ onClose }) => {
    const [popupData, setPopupData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchPopupData = async () => {
            const data = await getPopupData();
            // console.log("Fetched Popup Data: ", data); // Debugging to ensure data is fetched
            if (data && data.data && data.data.attributes) {
                setPopupData(data.data.attributes); // Correct data structure path
            } else {
                setError(true);
            }
            setLoading(false);
        };

        fetchPopupData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Loading state
    }

    if (error) {
        return <div>Failed to load popup content.</div>; // Error state
    }



    const renderMedia = () => {
        const media = popupData?.PopupMedia?.data?.attributes;
        if (!media) return null;

        const mimeType = media.mime;

        if (mimeType.startsWith('image')) {
            // Render image if mime type is an image
            return <img src={media.url} alt={media.alternativeText || 'Popup Image'} width="500" />;
        } else if (mimeType.startsWith('video')) {
            // Render video if mime type is a video
            return (
                <video controls width="500">
                    <source src={media.url} type={mimeType} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return null; // If no valid media type is found
    };

    return (
        <div className="popup-overlay">
            <div className="popup">
                {/* Display the title */}
                <h2>{popupData?.Title}</h2>



                {/* Display the media (image or video) */}
                <div className="popup-media">
                    {renderMedia()}
                </div>

                {/* Display the content dynamically */}
                <div>
                    {popupData?.Content?.map((paragraph, index) => (
                        <p key={index}>
                            {paragraph.children?.map((child, childIndex) => (
                                <span key={childIndex}>{child.text}</span>
                            ))}
                        </p>
                    ))}
                </div>

                <button onClick={onClose} className="popup-close-button">
                    Enter Site
                </button>
            </div>
        </div>
    );
};

export default PopupPage;
