import { API_BASE_CMS_URL } from './api.js';

export const getPopupData = async () => {
    try {
        const response = await fetch(`${API_BASE_CMS_URL}/api/popup?populate=*`);
        if (!response.ok) {
            throw new Error('Failed to fetch popup data');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching popup data: ", error);
        return null;
    }
};