import axios from 'axios';
import { API_BASE_URL } from './api.js';

// Function to handle the signup API call
export const signupUser = async (userData) => {
    try {
        // Send the userData object to the backend
        const response = await axios.post(`${API_BASE_URL}/signup`, {
            full_name: userData.full_name,
            email_address: userData.email_address,
            date_of_birth: userData.date_of_birth,
            address: userData.address,
            phone_number: userData.phone_number,
            gender: userData.gender,
            password: userData.password
        });

        return response.data; // Return the response data from the API
    } catch (error) {
        // console.error('Error during signup:', error);
        // If the error has a response from the server, return that error message; otherwise, return a generic message
        throw error.response ? error.response.data : new Error('Signup failed');
    }
};
