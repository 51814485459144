import { API_BASE_CMS_URL } from './api.js';

export const fetchAboutData = async () => {
    try {
        const response = await fetch(`${API_BASE_CMS_URL}/api/presidents-image?populate=*`);
        const data = await response.json();

        if (data && data.data) {
            const aboutItem = data.data;

            // Extract the image URL from the PresidentsImg field
            const imageUrl = aboutItem.attributes?.PresidentsImg?.data?.attributes?.formats?.large?.url || '';
            const altText = aboutItem.attributes?.PresidentsImg?.data?.attributes?.alternativeText || '';

            return {
                name: aboutItem.attributes?.name || '',
                description: aboutItem.attributes?.description || '',
                imageUrl,
                altText,
                author: aboutItem.attributes?.name || '',
            };
        }

        return null;
    } catch (error) {
        console.error('Error fetching about data:', error);
        return null;
    }
};
