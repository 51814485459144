import axios from 'axios';
import { API_BASE_URL } from './api.js';

export const handleSignIn = async (userData) => {
    try {
        // Use URLSearchParams to format the data as x-www-form-urlencoded
        const params = new URLSearchParams();
        params.append('username', userData.email);
        params.append('password', userData.password);

        // Make POST request with timeout and correct headers
        const response = await axios.post(`${API_BASE_URL}/token`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            },
            timeout: 15000 // Set a 5-second timeout
        });

        // Log the full response to the console
        // console.log('Full API Response:', response);

        // Return the access token from the response
        return response.data.access_token;
    } catch (error) {
        // Handle error scenarios
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.message || 'An error occurred during sign-in';
            // console.error('Error Response:', error.response); // Log the error response
            throw new Error(errorMessage);
        } else if (error.code === 'ECONNABORTED') {
            // Handle request timeout error
            // console.error('Request timed out:', error);
            throw new Error('The request took too long. Please try again.');
        } else {
            // console.error('API Error:', error); // Log the error object
            throw new Error('Unable to complete sign-in. Please try again later.');
        }
    }
};
