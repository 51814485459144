import React, { useState, useEffect } from 'react';
import AppRoutes from './routes/AppRoutes';
import './App.css';
import { ClipLoader } from 'react-spinners';
import { API_BASE_CMS_URL } from './service/api.js';

function App() {
  const [loading, setLoading] = useState(true);

  // Tawk.to script injection
  useEffect(() => {
    const script = document.createElement("script");
    script.src = 'https://embed.tawk.to/66e1aa2aea492f34bc11935c/1i7go1duc';
    script.async = true;
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up on unmount
    };
  }, []);

  // API check to wake up the backend
  useEffect(() => {
    const checkAPIs = async () => {
      while (loading) {
        try {
          // Ping the CMS API to wake it up
          const response = await fetch(API_BASE_CMS_URL);
          if (response.ok) {
            setLoading(false); // Stop loading when the API is awake
            break;
          } else {
            console.error("Failed to ping the server, retrying...");
          }
        } catch (error) {
          console.error("API error, retrying...", error);
        }

        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, 3000)); // Retry every 3 seconds
      }
    };

    checkAPIs();
  }, [loading]);

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader color={"green"} loading={loading} size={150} />
      </div>
    );
  }

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
