import React, { useEffect, useState } from 'react';
import '../style/Hero.css';
import { Link } from 'react-router-dom';
import { fetchVideoUrl } from '../service/heroVideo.js'; // Adjust path as necessary

const Hero = () => {
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        const getVideoUrl = async () => {
            try {
                const url = await fetchVideoUrl();
                if (url) {
                    setVideoUrl(url);

                } else {
                    // console.error('Video URL is empty');
                }
            } catch (error) {
                // console.error('Error fetching video URL:', error);
            }
        };

        getVideoUrl();

    }, []);

    return (
        <section className="hero">
            <div className="overlay"></div>
            <video autoPlay loop muted playsInline className="background-video">
                {videoUrl && <source src={videoUrl} type="video/mp4" />}
                {!videoUrl && <p>Loading video...</p>}
                Your browser does not support the video tag.
            </video>
            <div className="content">
                <h1 className="hero-title">Nigeria Natural Bodybuilding Association</h1>
                <p className="hero-subtitle">Fostering a community of like-minded individuals dedicated to health, fitness, and self-improvement.</p>
                <br />
                <div className="button-container">
                    <Link to="/register" className="home-button">Join Now</Link>

                </div>
            </div>
        </section>
    );
};

export default Hero;
